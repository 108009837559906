import * as React from "react";
import * as moment from "moment";

import { Activity, ActivityStockResult } from "./activityPlanner.types";
import { ChoiceReservedResource, MxtsApi, SubjectQuantity } from "@maxxton/cms-mxts-api";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { PriceTypes, TicketingTypes } from "./activityPlanner.enum";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { LocalizedActivityPrice } from "../../resultsPanel/price";
import { LocalizedAddOnPrice } from "../../dynamic/add-ons/addOns.util";
import { NumberUtil } from "../../../utils/number.util";
import { State } from "../../../redux";
import { getCapacities } from "../travel-party/travelParty.util";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isEqual } from "lodash";
import namespaceList from "../../../i18n/namespaceList";
import { useSelector } from "react-redux";

interface ActivityPriceProps {
    addOnPriceLocalizations: LocalizedAddOnPrice[] | LocalizedActivityPrice[];
    context: CMSProvidedProperties;
    activity: Activity;
    className?: string;
    style?: React.CSSProperties;
    showActivityBasePrice?: boolean;
    showFromPriceForSubjectBasedActivity?: boolean;
    hideDynamicTextForSubjectFromPrice?: boolean;
    widgetType: "page" | "resultsPanel";
}

export enum ValueType {
    SINGLE = "SINGLE", // Just a normal fixed price
    QRV = "QRV", // Price depends on the number of subjects (or even price is different for Adults and Children)
}

export interface SubjectQuantityResult extends SubjectQuantity {
    subjectName: string | undefined;
}

// eslint-disable-next-line max-lines-per-function
export const ActivityPrice = (props: ActivityPriceProps): JSX.Element | null => {
    const { addOnPriceLocalizations, context, activity, className, style, showActivityBasePrice, widgetType } = props;
    const { currentLocale, site } = context;
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});
    // when the activity priceType is free in mxts
    const checkFreeActivity = activity.resourceActivity.resortActivity.priceType === PriceTypes.FREE;
    // when priceType could be paid/free but there is no price config option in mxts eg: NO_TICKETS
    const checkNoPriceConfigured = activity.resourceActivity.resortActivity.ticketingType === TicketingTypes.NO_TICKETS;
    const fixedPrice: ChoiceReservedResource[] = [];
    const subjectBasedPrice: ChoiceReservedResource[] = [];
    const [subjectBasedPrices, setSubjectBasedPrices] = React.useState<ChoiceReservedResource[]>(subjectBasedPrice);
    const dynamicActivityPriceText = getLocalizedContent({ site, currentLocale, localizedContent: addOnPriceLocalizations as LocalizedAddOnPrice[] })?.dynamicActivityPriceText;
    activity.pricesResult?.addOns.forEach((priceResult) => {
        if (priceResult.usedCashflowrules?.[0]?.valueType === ValueType.QRV) {
            // Subject based rate contains value type as QRV
            priceResult.subjects.forEach((subject) => {
                const foundReservability = activity?.activityReservabilities.find((activityReservability) => activityReservability.activityStock.subjectId === subject.subjectId);
                if (foundReservability) {
                    (subject as SubjectQuantityResult).subjectName = (foundReservability.activityStock as ActivityStockResult).subjectName;
                }
            });
            subjectBasedPrice.push(priceResult);
        }
        if (priceResult.usedCashflowrules?.[0]?.valueType === ValueType.SINGLE) {
            // Fixed rate contains value type as SINGLE
            fixedPrice.push(priceResult);
        }
    });

    React.useEffect(() => {
        const updateInheritedSubjectPrices = async () => {
            if (subjectBasedPrice.length === 1) {
                const env = await getMxtsEnv(context);
                const arrivalDate = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS) : activity.day || undefined;
                const capacitiesFromMxts = await getCapacities({
                    mxtsApi: MxtsApi,
                    env,
                    resourceId: subjectBasedPrice[0].resourceId,
                    inherited: true,
                    resourceActivityDetailsId: subjectBasedPrice[0].resourceActivityDetailsId,
                    arrivalDate,
                });
                if (capacitiesFromMxts.length > 1 && !capacitiesFromMxts.some((capacity) => capacity.selfCapacity)) {
                    const cashflowRuleIds: number[] = (await MxtsApi.getRates(env, { cashflowManagerId: activity.resourceActivity.cashflowManagerId, size: MXTS.PAGE_REQUEST_SIZE })).content.map(
                        (rate) => rate.cashflowruleId
                    );
                    const subjectRates = (await MxtsApi.getRates(env, { cashflowRuleIds, size: MXTS.PAGE_REQUEST_SIZE, view: "detail" })).content;

                    const subjectsAndRates = subjectRates.map((subjectRate) => {
                        const matchingCapacity = capacitiesFromMxts.find((capacity) => capacity.subjectId === subjectRate.subjects[0].subjectId);
                        return { matchingCapacity, subjectRate };
                    });
                    const subjectBasedPriceObjects: ChoiceReservedResource[] = subjectsAndRates.map((subjectAndRate) => {
                        const selectedSubjectQuantity = dynamicFilter.subject?.get(subjectAndRate.matchingCapacity?.subjectId!) || 1;
                        const subjectAndQuantity: SubjectQuantityResult[] = [
                            { subjectId: subjectAndRate.matchingCapacity?.subjectId!, quantity: selectedSubjectQuantity, subjectName: subjectAndRate.matchingCapacity?.name },
                        ];
                        return {
                            ...subjectBasedPrice[0],
                            subjects: subjectAndQuantity,
                            usedCashflowrules: [subjectAndRate.subjectRate],
                            internalPrice: subjectAndRate.subjectRate.qrvValue,
                            totalInternalPrice: subjectAndRate.subjectRate.qrvValue * selectedSubjectQuantity,
                        };
                    });
                    setSubjectBasedPrices(subjectBasedPriceObjects);
                }
            } else if (!isEqual(subjectBasedPrices[0], subjectBasedPrice[0])) {
                setSubjectBasedPrices(subjectBasedPrice);
            }
        };
        if (subjectBasedPrices.length <= subjectBasedPrice.length) {
            updateInheritedSubjectPrices();
        }
    }, [subjectBasedPrice]);

    // If the activity is free
    if (checkFreeActivity) {
        const freeAddOnLocalization = getLocalizedContent({ site, currentLocale, localizedContent: addOnPriceLocalizations as LocalizedAddOnPrice[] })?.freeAddOn;
        const freeActivityLocalization = getLocalizedContent({ site, currentLocale, localizedContent: addOnPriceLocalizations as LocalizedActivityPrice[] })?.freeActivity;
        return <div className={`price free-activity activity-item ${className}`}>{freeAddOnLocalization || freeActivityLocalization}</div>;
    }

    if (checkNoPriceConfigured) {
        const noPriceText = getLocalizedContent({ site, currentLocale, localizedContent: addOnPriceLocalizations as LocalizedActivityPrice[] })?.noPriceText;
        if (noPriceText) {
            return (
                <div className={`no-price-configured activity-item ${className}`} style={style}>
                    {noPriceText}
                </div>
            );
        }
        return <div className={`no-price-configured activity-item ${className}`} style={style}></div>;
    }

    // If the activity is paid
    if (fixedPrice.length) {
        let fixPrice = fixedPrice[0].totalInternalPrice || fixedPrice[0].internalPrice;
        const ticketingType = activity.resourceActivity.resortActivity.ticketingType;
        const isTicketingTypeTicketsOrGroups = ticketingType === TicketingTypes.TICKETS || ticketingType === TicketingTypes.TICKET_PER_GROUP;
        if (isTicketingTypeTicketsOrGroups && dynamicFilter.selectedActivities?.length && !dynamicFilter.showMainActivityOnPage && !dynamicFilter.activityTicketQuantity && widgetType === "page") {
            // When 0 is selected in ticket counter, the price should be displayed as 0 for fixed price.
            fixPrice = fixedPrice[0].totalInternalPrice || 0;
        }
        if (isTicketingTypeTicketsOrGroups && dynamicFilter.showMainActivityOnPage && !dynamicFilter.resourceActivityDetailsIds?.length) {
            // When no timeslots are selected in bowling section table, the price should be displayed as 0 for fixed price
            fixPrice = 0;
        }
        if (isTicketingTypeTicketsOrGroups && showActivityBasePrice) {
            // Display the base price of the activity that is independent of ticket selection.
            fixPrice = fixedPrice[0].internalPrice;
        }
        return (
            <div className="activity-item">
                <label>{dynamicActivityPriceText || getI18nLocaleString(namespaceList.widgetActivityPlanner, "activityFixedPrice")}</label>
                <div className="price__wrap">
                    <span className={`${className} price-value`} style={style}>
                        {NumberUtil.priceWithCurrency({
                            // When bowling table is used that means resourceActivityDetailsIds present, in this case sum all the selected totalInternalPrice.
                            // Fixed rate will have same rate for all the subjects that's why we are taking first fixed rate hence fixedPrice[0].totalInternalPrice.
                            price: dynamicFilter.resourceActivityDetailsIds?.length ? fixedPrice.reduce((sum, item) => sum + (item.totalInternalPrice as number), 0) : fixPrice,
                            context,
                            currencyCode: dynamicFilter.currency?.code,
                        })}
                    </span>
                </div>
            </div>
        );
    }

    if (subjectBasedPrices?.length) {
        const lowestPrice = subjectBasedPrices.reduce((min, current) => (current.internalPrice < min.internalPrice && current.internalPrice !== 0 ? current : min));
        const shouldShowActivityPriceText = props.showFromPriceForSubjectBasedActivity ? !props.hideDynamicTextForSubjectFromPrice : true;
        return (
            <div className="activity-item">
                {shouldShowActivityPriceText && <label>{dynamicActivityPriceText || getI18nLocaleString(namespaceList.widgetActivityPlanner, "activitySubjectBasedPrice")}</label>}
                {props.showFromPriceForSubjectBasedActivity && lowestPrice ? (
                    <React.Fragment>
                        {/* Show "from" label above price when not showing price type text */}
                        {!shouldShowActivityPriceText && <label className="price-label">{getI18nLocaleString(namespaceList.widgetActivityPlanner, "from", currentLocale, site)}</label>}
                        <div className="price__wrap">
                            {/* Show "from" label inline when showing price type text */}
                            {shouldShowActivityPriceText && <label className="price-label">{getI18nLocaleString(namespaceList.widgetActivityPlanner, "from", currentLocale, site)}</label>}
                            <span className={`${className} price-value`} style={style}>
                                {NumberUtil.priceWithCurrency({
                                    price: lowestPrice.internalPrice,
                                    context,
                                    currencyCode: dynamicFilter.currency?.code,
                                })}
                            </span>
                        </div>
                    </React.Fragment>
                ) : (
                    subjectBasedPrices.map((price, index) => {
                        let subjectPrice = price.totalInternalPrice || price.internalPrice;
                        if (showActivityBasePrice) {
                            subjectPrice = price.internalPrice;
                        }
                        return (
                            <div key={index} className="price__wrap">
                                <label className="price-label">{(price.subjects[0] as SubjectQuantityResult).subjectName}</label>
                                <span className={`${className} price-value`} style={style}>
                                    {NumberUtil.priceWithCurrency({
                                        price: subjectPrice,
                                        context,
                                        currencyCode: dynamicFilter.currency?.code,
                                    })}
                                </span>
                            </div>
                        );
                    })
                )}
            </div>
        );
    }

    return null;
};
