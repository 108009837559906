import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AccommodationType, Unit, getMxtsEnv } from "../../mxts";
import { DispatchOpenLinkedTabOptions, setPageViewEvent } from "../resultsPanelUtil";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { backgroundColorPicker, fontColorPicker } from "../../../utils/colorpicker.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityPrice } from "../../page/activityPlanner/ActivityPrice";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DISPLAY_PRICE_INFO } from "../../shared/priceMatrix/priceMatrix.enum";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { NumberUtil } from "../../../utils/number.util";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { StayPeriodDef } from "@maxxton/cms-mxts-api";
import { SubjectUtil } from "../../dynamic/subject/SubjectUtil";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { WidgetOptions } from "./";
import { isEqual as _isEqual } from "lodash";
import { connect } from "react-redux";
import { getActivityPrice } from "../../page/activityPlanner/activityPlanner.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";
import { stayPeriodDefById } from "../../mxts/mxts.util";

interface PriceWidgetProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    unit?: Unit;
    unitBookUri?: string;
    dynamicFilter?: DynamicFilter;
    amenityCodes?: string[];
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
    warmupState?: PriceWidgetState;
    activity?: Activity | undefined;
}

interface PriceWidgetState {
    dynamicFields: string[];
    specialName: string;
    specialDescription: string;
    dynamicPriceFieldCode: DynamicFieldDescription[];
    priceModalOpen: boolean;
    totalPrice: number;
    isTotalPriceLoading: boolean;
    unitStayPeriodDefName?: string;
    activity: Activity | undefined;
}

interface PriceStoreStateProps {
    dynamicFilter: DynamicFilter;
}

export interface DynamicFieldDescription {
    value: string;
    code: string;
}

type PriceStateHandler = StateHandler<PriceWidgetProps, PriceWidgetState>;

export class PriceBase extends React.Component<PriceWidgetProps, PriceWidgetState> {
    private controller: AbortController = new AbortController();
    private _isMounted = false;

    public static async warmupCache(props: PriceWidgetProps): Promise<PriceWidgetState> {
        return warmupState(props, PriceBase.defaultState(props), async (stateHandler) => {
            await PriceBase.getTotalPrice(props, stateHandler);
            await PriceBase.setPricePopupDetails(props, stateHandler);
        });
    }

    private static defaultState(props: PriceWidgetProps): PriceWidgetState {
        return {
            dynamicFields: [],
            specialName: "",
            specialDescription: "",
            dynamicPriceFieldCode: [],
            priceModalOpen: false,
            totalPrice: 0,
            isTotalPriceLoading: false,
            unitStayPeriodDefName: "",
            activity: props.activity,
        };
    }

    constructor(props: PriceWidgetProps) {
        super(props);
        this.state = {
            ...PriceBase.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    public componentDidMount() {
        PriceBase.setPricePopupDetails(this.props, this, this);
        const { unit, dynamicFilter, context } = this.props;
        const { activity } = this.state;
        this._isMounted = true;
        const { options } = this.props;
        if (options.showTotalPrice) {
            PriceBase.getTotalPrice(this.props, this);
        }
        if (!unit?.stayPeriodDefName) {
            this.getUnitStayPeriodDefName(this);
        }

        (async () => {
            const env = await getMxtsEnv(context, context.currentLocale.code);
            // Set activity price
            if (activity && dynamicFilter && env) {
                const updatedActivity = await getActivityPrice(activity, dynamicFilter, env);
                this.setState({ activity: updatedActivity });
            }
        })();
    }
    public async componentDidUpdate(prevProps: PriceWidgetProps) {
        const { options, accommodationType } = this.props;
        if (!_isEqual(prevProps.dynamicFilter, this.props.dynamicFilter) || !_isEqual(prevProps.accommodationType, accommodationType)) {
            if (options.showTotalPrice) {
                PriceBase.getTotalPrice(this.props, this);
            }
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: PriceWidgetProps) {
        if (this.props.unit && this.props.unit.unitId !== nextProps.unit!.unitId) {
            PriceBase.setPricePopupDetails(nextProps, this, this);
        }
        if (!_isEqual(this.props.unit, nextProps.unit)) {
            PriceBase.getTotalPrice(nextProps, this);
        }
    }

    public componentWillUnmount() {
        this._isMounted = false;
        this.controller.abort();
    }

    public renderInfoIcon(inclusivePrice?: number | null): JSX.Element | null {
        const {
            accommodationType,
            dynamicFilter,
            options,
            unit,
            context: { currentLocale, site },
        } = this.props;
        const { dynamicPriceFieldCode, isTotalPriceLoading, specialName, specialDescription, totalPrice } = this.state;
        return (
            <span className="price-value">
                {options.showFromPrice && (
                    <span className="space-mr-xs small-font-size display-price-label">{getI18nLocaleString(namespaceList.widgetResultsPrice, "textBeforePrice", currentLocale, site)}</span>
                )}
                {isTotalPriceLoading || (options?.showTotalPrice && !totalPrice) ? (
                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                ) : (
                    <span>
                        {NumberUtil.priceWithCurrency({
                            price: inclusivePrice,
                            isRelativePrice: options.showTotalPrice ? !options.showTotalPrice : !site?.addDecimalToPrices,
                            context: this.props.context,
                            currencyCode: dynamicFilter?.currency?.code,
                            showCurrency: !options.disableCurrencySymbol,
                        })}
                    </span>
                )}
                {options.showInfoIconAfterPrice && (dynamicPriceFieldCode.length || specialName || specialDescription) && !(options.displayPriceInfo === DISPLAY_PRICE_INFO.NONE) ? (
                    <div className="display-price__more-info" onClick={options.displayPriceInfo === DISPLAY_PRICE_INFO.MODAL ? this.handlePriceInfoModal : undefined}>
                        <FontAwesome name="question-circle" />
                        {(unit && (unit.specialId || dynamicPriceFieldCode.length)) || (accommodationType && (accommodationType.specialId || dynamicPriceFieldCode.length))
                            ? options.displayPriceInfo === DISPLAY_PRICE_INFO.MODAL
                                ? this.priceInfoModal()
                                : this.priceInfoDropdown()
                            : null}
                    </div>
                ) : null}
            </span>
        );
    }

    public static async getTotalPrice(props: PriceWidgetProps, stateHandler: PriceStateHandler): Promise<void> {
        const { unit, accommodationType, context, dynamicFilter } = props;
        if (dynamicFilter) {
            const {
                startdate: startDate,
                enddate: endDate,
                reservationCategoryId,
                rateType: { rateTypeId } = {},
                distributionChannel: { distributionChannelId } = {},
                subject,
                resourceid,
            } = dynamicFilter;
            const resourceId = accommodationType?.resourceId || unit?.resourceId || resourceid;
            let offerSearchCode = accommodationType?.specialCode[0] || unit?.specialCode?.[0] || dynamicFilter?.specialcode?.[0];
            if (offerSearchCode === "no-special") {
                offerSearchCode = undefined;
            }
            const dynamicStartDate = moment(startDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS);
            const dynamicEndDate = moment(endDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS);
            const startDateAcco = dynamicStartDate !== accommodationType?.arrivalDate ? accommodationType?.arrivalDate || "" : dynamicStartDate;
            const endDateAcco = dynamicEndDate !== accommodationType?.departureDate ? accommodationType?.departureDate || "" : dynamicEndDate;
            const unitArrivalDate = unit?.date || unit?.arrivalDate;
            const unitDepartureDate = unit?.departureDate || moment(unitArrivalDate, DATE_FORMAT.ELASTIC).add(unit?.duration?.[0], "days").format(DATE_FORMAT.MXTS);
            const startDateUnit = dynamicStartDate !== unitArrivalDate ? unitArrivalDate || "" : dynamicStartDate;
            const endDateUnit = dynamicEndDate !== unitDepartureDate ? unitDepartureDate || "" : dynamicEndDate;
            if (
                distributionChannelId &&
                (dynamicStartDate || accommodationType?.arrivalDate) &&
                (dynamicEndDate || accommodationType?.departureDate) &&
                reservationCategoryId &&
                rateTypeId &&
                resourceId
            ) {
                const env = await getMxtsEnv(context, context.currentLocale.code);
                const calculateTotalRequest = {
                    distributionChannelId,
                    reservationCategoryId,
                    rateTypeId,
                    startDate: accommodationType ? startDateAcco : startDateUnit,
                    endDate: accommodationType ? endDateAcco : endDateUnit,
                    offerSearchCode,
                    includeBill: false,
                    accommodationType: {
                        subjects: SubjectUtil.getSubjectQuantitiesFromMap(subject),
                        resourceId,
                        type: "ACCOMMODATIONTYPE",
                        status: "INITIAL",
                    },
                };
                stateHandler.setState({ isTotalPriceLoading: true, totalPrice: 0 });
                const calculationResult = await context.mxtsApi.calculateTotal(env, calculateTotalRequest);
                stateHandler.setState({ totalPrice: calculationResult.totalPrice, isTotalPriceLoading: false });
            }
        }
    }
    public async getUnitStayPeriodDefName(tthis?: PriceBase) {
        const { unit, context } = this.props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const fetchedStayPeriodDefs: StayPeriodDef[] = env
            ? await context?.mxtsApi.stayPeriodDefs(env, { stayPeriodDefIds: unit?.stayPeriodDefId }, undefined, tthis?.controller?.signal).then((result) => result.content)
            : [];
        const stayPeriodDef: StayPeriodDef | null = unit?.stayPeriodDefId ? stayPeriodDefById(fetchedStayPeriodDefs ? fetchedStayPeriodDefs : [], unit.stayPeriodDefId) : null;
        this.setState({ unitStayPeriodDefName: stayPeriodDef?.name });
    }
    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { options, unit, accommodationType, context, unitBookUri, dynamicFilter, amenityCodes } = this.props;
        const { unitStayPeriodDefName, dynamicPriceFieldCode, totalPrice, activity } = this.state;
        const accommodationTypeReferencePrice = accommodationType?.referencePriceInclusive;
        const unitReferencePrice = unit ? unit.referencePriceInclusive : undefined;
        const { currentLocale, site } = context;
        const hideWidget = setOpacityOnHide(options);
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor, priceFontColor, priceFontSize, priceTextBackground, priceFontStyle } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const accommodationTypePriceInclusive: number | undefined = (options.showTotalPrice && totalPrice ? totalPrice : accommodationType?.priceInclusive) || accommodationType?.nightPriceInclusive;
        const accommodationTypeBasePriceInclusive: number | undefined = accommodationType?.basePriceInclusive || accommodationType?.priceInclusive;
        const unitNightPriceInclusive: number | undefined | null = unit?.averageNightPrice && !options.showTotalNightPrice ? Math.round(unit.averageNightPrice) : unit?.unitNightPriceInclusive;
        const unitBaseNightPriceInclusive: number | undefined | null = unit?.averageBaseNightPrice ? Math.round(unit.averageBaseNightPrice) : unit?.unitBaseNightPriceInclusive;
        const unitPriceInclusive: number | undefined | null = (options.showTotalPrice && totalPrice ? totalPrice : unit?.unitSpecialPriceInclusive) || unit?.unitNightPriceInclusive;
        const unitBasePriceInclusiveValue: number | undefined = unit?.unitBasePriceInclusive || unit?.unitSpecialPriceInclusive;
        const accommodationTypeNightPriceInclusive: number | undefined | null = accommodationType?.baseNightPriceInclusive || accommodationType?.nightPriceInclusive;
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(currentLocale.code, unit, accommodationType, unitBookUri, dynamicFilter, amenityCodes);
        const callSetViewEvent = () => {
            setPageViewEvent(context, bookUrl, accommodationType, unit);
        };
        const regExp = new RegExp("\\$count");
        const referencePrice = accommodationTypeReferencePrice || unitReferencePrice;
        const baseInclusivePrice = accommodationTypeBasePriceInclusive || unitBasePriceInclusiveValue;
        const inclusivePrice = (options.perNight ? accommodationTypeNightPriceInclusive : accommodationTypePriceInclusive) || (options.perNight ? unitNightPriceInclusive : unitPriceInclusive);

        return accommodationTypePriceInclusive || unitNightPriceInclusive ? (
            <div className={`display-price ${hideWidget} ${options?.tabLink ? "cursor-pointer" : ""}`} onClick={this.handleDispatchOpenLinkedTabAction}>
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                    style={classNames(fontColor?.includes("rgba") && fontColor)}
                />
                {options.showReferencePrice &&
                    !options.showOldPrice &&
                    ((accommodationTypeReferencePrice && accommodationTypePriceInclusive && accommodationTypePriceInclusive < accommodationTypeReferencePrice) ||
                        (unitReferencePrice && unitBaseNightPriceInclusive && unitBaseNightPriceInclusive < unitReferencePrice)) && (
                        <div className="old-price">
                            {options.showFromOldPrice && <span className="space-mr-xxs">{getI18nLocaleString(namespaceList.widgetTypeSearch, "from", currentLocale, site)}</span>}
                            {referencePrice && (
                                <span className="old-price-value">
                                    <del>
                                        {NumberUtil.priceWithCurrency({
                                            price: referencePrice,
                                            isRelativePrice: options.showTotalPrice ? !options.showTotalPrice : !site?.addDecimalToPrices,
                                            context,
                                            currencyCode: dynamicFilter?.currency?.code,
                                            showCurrency: !options.disableCurrencySymbol,
                                        })}
                                    </del>
                                </span>
                            )}
                            {options.perNight ? <span className="price-per-night">{getI18nLocaleString(namespaceList.widgetResultsPrice, "pricePerNight", currentLocale, site)}</span> : null}
                        </div>
                    )}
                {options.showOldPrice &&
                    !this.state.isTotalPriceLoading &&
                    !options.showReferencePrice &&
                    ((accommodationTypePriceInclusive && accommodationTypeBasePriceInclusive && accommodationTypePriceInclusive < accommodationTypeBasePriceInclusive) ||
                        (accommodationTypeBasePriceInclusive && options.showTotalPrice && totalPrice < accommodationTypeBasePriceInclusive) ||
                        (unitPriceInclusive && unitBasePriceInclusiveValue && unitPriceInclusive < unitBasePriceInclusiveValue)) && (
                        // Remove old code because need to show old price for unit page.
                        <div className="old-price">
                            {options.showFromOldPrice && <span className="space-mr-xxs">{getI18nLocaleString(namespaceList.widgetTypeSearch, "from", currentLocale, site)}</span>}
                            {(accommodationTypeBasePriceInclusive || unitBasePriceInclusiveValue) && (
                                <span className="old-price-value">
                                    <del>
                                        {NumberUtil.priceWithCurrency({
                                            price: baseInclusivePrice,
                                            isRelativePrice: options.showTotalPrice ? !options.showTotalPrice : !site?.addDecimalToPrices,
                                            context,
                                            currencyCode: dynamicFilter?.currency?.code,
                                            showCurrency: !options.disableCurrencySymbol,
                                        })}
                                    </del>
                                </span>
                            )}
                            {options.perNight ? <span className="price-per-night">{getI18nLocaleString(namespaceList.widgetResultsPrice, "pricePerNight", currentLocale, site)}</span> : null}
                        </div>
                    )}
                <span
                    className={classNames("display-price__price", fontColorPicker(priceFontColor), backgroundColorPicker(priceFontStyle, priceTextBackground), {
                        [priceFontSize]: !!priceFontSize,
                    })}
                    style={{
                        color: options.priceFontColor && options.priceFontColor.indexOf("rgba") > -1 ? options.priceFontColor : undefined,
                        background: options.priceFontStyle !== "default" && options.priceTextBackground && options.priceTextBackground.indexOf("rgba") > -1 ? options.priceTextBackground : undefined,
                    }}
                >
                    {options.enableBookingsEngineLink ? (
                        <SmartLink
                            href={bookUrl}
                            target="_blank"
                            rel="noreferrer"
                            onClick={callSetViewEvent}
                            className={`${priceFontSize ? priceFontSize : ""} ${fontColorPicker(priceFontColor)} ${backgroundColorPicker(priceFontStyle, priceTextBackground)}
                                        `}
                            style={{
                                color: options.priceFontColor && options.priceFontColor.indexOf("rgba") > -1 ? options.priceFontColor : undefined,
                                background:
                                    options.priceFontStyle !== "default" && options.priceTextBackground && options.priceTextBackground.indexOf("rgba") > -1 ? options.priceTextBackground : undefined,
                            }}
                        >
                            {this.renderInfoIcon(inclusivePrice)}
                        </SmartLink>
                    ) : (
                        this.renderInfoIcon(inclusivePrice)
                    )}
                    {options.perNight ? <span className="price-per-night">{getI18nLocaleString(namespaceList.widgetResultsPrice, "pricePerNight", currentLocale, site)}</span> : null}
                </span>
                {!options.showInfoIconAfterPrice && !(options.displayPriceInfo === DISPLAY_PRICE_INFO.NONE) ? (
                    <div className="price-more-info" onClick={options.displayPriceInfo === DISPLAY_PRICE_INFO.MODAL ? this.handlePriceInfoModal : undefined}>
                        <FontAwesome name="question-circle" />
                        {(unit && (unit.specialId || dynamicPriceFieldCode.length)) || (accommodationType && (accommodationType.specialId || dynamicPriceFieldCode.length))
                            ? options.displayPriceInfo === DISPLAY_PRICE_INFO.MODAL
                                ? this.priceInfoModal()
                                : this.priceInfoDropdown()
                            : null}
                    </div>
                ) : null}

                {accommodationType && options.showDateAndStay && (
                    <ul className="card-box-object__date">
                        {!options.hideArrivalDate && (
                            <li className={`card-box-object__date__item arrivaldate ${accommodationType.alternative && "alternative-date"}`}>
                                {options.showDepartureDate ? (
                                    <React.Fragment>
                                        <label className="arrival-date">{`${moment(accommodationType.arrivalDate).format(options.dateFormat || DATE_FORMAT.DISPLAY)}`}</label>
                                        {accommodationType.departureDate && (
                                            <React.Fragment>
                                                <FontAwesome name="arrow-right" className="arrow-right-icon" />
                                                <label className="departure-date">{`${moment(accommodationType.departureDate).format(options.dateFormat || DATE_FORMAT.DISPLAY)}`}</label>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    moment(accommodationType.arrivalDate).format(options.dateFormat || DATE_FORMAT.DISPLAY)
                                )}
                            </li>
                        )}
                        {!options.hideStayPeriod && <li className="card-box-object__date__item stay">{accommodationType.stayPeriodDefName}</li>}
                        {!options.hideMinimumStayPeriod && (
                            <li className="card-box-object__date__item minimum-stay-duration">
                                {accommodationType.duration[0]
                                    ? getI18nLocaleString(namespaceList.widgetTypeSearch, "minimum_stay_of", currentLocale, site).replace(regExp, accommodationType.duration[0].toString())
                                    : ""}
                            </li>
                        )}
                    </ul>
                )}
                {unit && options.showDateAndStay && (
                    <ul className="card-box-object__date">
                        <li className={`card-box-object__date__item arrivaldate ${unit.alternative && "alternative-date"}`}>{moment(unit.date).format(options.dateFormat || DATE_FORMAT.DISPLAY)}</li>
                        {(unit?.stayPeriodDefName || unitStayPeriodDefName) && !options.hideStayPeriod && (
                            <li className="card-box-object__date__item stay">{unit?.stayPeriodDefName || unitStayPeriodDefName}</li>
                        )}
                        {unit.minDuration && !options.hideMinimumStayPeriod && (
                            <li className="card-box-object__date__item minimum-stay-duration">
                                {getI18nLocaleString(namespaceList.widgetTypeSearch, "minimum_stay_of", currentLocale, site).replace(regExp, unit.minDuration.toString())}
                            </li>
                        )}
                        {unit.duration && unit.duration[0] && !options.hideMinimumStayPeriod && (
                            <li className="card-box-object__date__item minimum-stay-duration">
                                {getI18nLocaleString(namespaceList.widgetTypeSearch, "minimum_stay_of", currentLocale, site).replace(regExp, unit.duration[0].toString())}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        ) : activity ? (
            <ActivityPrice
                className={`${options.priceFontSize || ""} ${fontColorPicker(priceFontColor)} ${backgroundColorPicker(priceFontStyle, priceTextBackground)}`}
                activity={activity}
                addOnPriceLocalizations={options.freeActivityLocalization}
                context={context}
                showFromPriceForSubjectBasedActivity={options.showFromPriceForSubjectBasedActivity}
                hideDynamicTextForSubjectFromPrice={options.hideDynamicTextForSubjectFromPrice}
                widgetType="resultsPanel"
            />
        ) : null;
    }

    private handleDispatchOpenLinkedTabAction = () => {
        const { dispatchOpenLinkedTabAction, options } = this.props;
        if (typeof dispatchOpenLinkedTabAction === "function") {
            dispatchOpenLinkedTabAction(options);
        }
    };

    private priceInfoDropdown = () => {
        const { specialName, specialDescription, dynamicPriceFieldCode } = this.state;
        const { options } = this.props;
        return (
            <div className="price-tooltip">
                {!options.hideSpecialInPriceInfo && <span className="special-name">{specialName}</span>}
                {!options.hideSpecialInPriceInfo && specialDescription?.includes("</") ? (
                    <span className="special-description" dangerouslySetInnerHTML={{ __html: specialDescription }} />
                ) : (
                    <span className="special-description">{specialDescription}</span>
                )}

                {dynamicPriceFieldCode.map((field, index) => {
                    if (field.value.includes("</")) {
                        return <span className={`dynamic-price-field ${field.code}`} key={index} dangerouslySetInnerHTML={{ __html: field.value }} />;
                    }
                    return (
                        <span className={`dynamic-price-field ${field.code}`} key={index}>
                            {field.value}
                        </span>
                    );
                })}
            </div>
        );
    };
    private priceInfoModal = () => {
        const { specialName, specialDescription, priceModalOpen, dynamicPriceFieldCode } = this.state;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        return (
            <Modal isOpen={priceModalOpen} toggle={this.handlePriceInfoModal} size="s" className={"result-panel-button help-modal special-modal"}>
                <ModalHeader tag="h4" toggle={this.handlePriceInfoModal} className="modal-title-bordered">
                    {getI18nLocaleString(namespaceList.widgetResultsPrice, "specialPrice", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="price-info">
                        {!options.hideSpecialInPriceInfo && <span className="special-name">{specialName}</span>}
                        {!options.hideSpecialInPriceInfo && specialDescription?.includes("</") ? (
                            <span className="special-description" dangerouslySetInnerHTML={{ __html: specialDescription }} />
                        ) : (
                            <span className="special-description">{specialDescription}</span>
                        )}

                        {dynamicPriceFieldCode.map((field, index) => {
                            if (field.value.includes("</")) {
                                return <span className={`dynamic-price-field ${field.code}`} key={index} dangerouslySetInnerHTML={{ __html: field.value }} />;
                            }
                            return (
                                <span className={`dynamic-price-field ${field.code}`} key={index}>
                                    {field.value}
                                </span>
                            );
                        })}
                    </div>
                </ModalBody>
            </Modal>
        );
    };
    /* jscpd:ignore-end */
    private handlePriceInfoModal = () => {
        this._isMounted && this.setState({ priceModalOpen: !this.state.priceModalOpen });
    };

    private static async setPricePopupDetails(props: PriceWidgetProps, stateHandler: PriceStateHandler, tthis?: PriceBase): Promise<void> {
        const { options, context, unit, accommodationType } = props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        let { specialName, specialDescription } = stateHandler.state;
        const dynamicFieldCode =
            (options.dynamicFieldCode && options.dynamicFieldCode.trim().split(",")) || (options.dynamicFieldCodeList && options.dynamicFieldCodeList.map((dynamicField) => dynamicField.value));
        if (unit && (!unit.specialId || options.hideSpecialInPriceInfo) && (options.dynamicFieldCode || options.dynamicFieldCodeList)) {
            await PriceBase.getDynamicFieldCodeDesc(unit, dynamicFieldCode, env, stateHandler, options, tthis);
        } else if (accommodationType && (!accommodationType.specialId || options.hideSpecialInPriceInfo) && (options.dynamicFieldCode || options.dynamicFieldCodeList)) {
            await PriceBase.getDynamicFieldCodeDesc(accommodationType, dynamicFieldCode, env, stateHandler, options, tthis);
        } else if (accommodationType && accommodationType.specialId) {
            specialDescription = accommodationType.specialDescription;
            specialName = accommodationType.specialName;
        } else if (unit && unit.specialId) {
            const special = await context.mxtsApi.resources(env, { resourceIds: [unit.specialId] }).then((resources) => resources.content);
            specialDescription = special[0].description;
            specialName = special[0].name;
        }
        tthis?._isMounted && stateHandler.setState({ specialName, specialDescription });
    }

    private static async getDynamicFieldCodeDesc(
        type: AccommodationType | Unit,
        dynamicFieldCodeList: string[] | undefined,
        env: any,
        stateHandler: PriceStateHandler,
        widgetOptions: WidgetOptions,
        tthis?: PriceBase
    ) {
        try {
            if (dynamicFieldCodeList && type?.dynamicManagerId) {
                const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["dynamicFieldCode", "dynamicFieldCodeList"];

                const dynamicFieldPromises = dynamicFieldCodeList.map((field: string) =>
                    stateHandler.props.context.mxtsApi
                        .dynamicFieldsInfoCustomized(
                            env,
                            { managerId: type.dynamicManagerId, code: field, checkFallback: true, widgetOptionsId: widgetOptions._id, widgetOptionsDynamicFieldCodesPaths },
                            undefined,
                            tthis?.controller.signal
                        )
                        .then((info) => {
                            if (info?.[0]?.value) {
                                const obj = {
                                    value: info[0].value,
                                    code: info[0].code,
                                };
                                return obj;
                            }
                        })
                );
                const dynamicPriceFieldCode = await Promise.all(dynamicFieldPromises).then((dynamicFields) => dynamicFields.filter((dynamicField) => dynamicField) as DynamicFieldDescription[]);
                tthis?._isMounted && stateHandler.setState({ dynamicPriceFieldCode });
            }
        } catch (err) {
            return;
        }
    }
}

function mapStateToProps(state: State): PriceStoreStateProps {
    return {
        dynamicFilter: state.dynamicFilter,
    };
}
export const Price = wrapProps<PriceWidgetProps>(connect<PriceStoreStateProps, null>(mapStateToProps, null)(PriceBase));
