import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { I18nDYNAMICFIELDCODE, dynamicFieldPrice } from "../../../components/utils";
import { LocalizedContentBase, WithId } from "@maxxton/cms-api";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { Price, PriceBase } from "./Price";

import { DATE_FORMAT } from "../../../utils/constants";
import { DISPLAY_PRICE_INFO } from "../../shared/priceMatrix/priceMatrix.enum";
import { SharedWidgetParams } from "../../widget";
import { TypesearchContainerWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { WidgetOptionsFormParams } from "../../shared/location";
import { dateFormats } from "../resultsPanelUtil";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { tabLinkAutocomplete } from "../../../inputSpecs";

export interface WidgetOptions extends LocalizedTitleOptions, WithId {
    styleIds?: string[];
    priceFontSize: string;
    priceFontColor: string;
    priceFontStyle: string;
    priceTextBackground: string;
    perNight: boolean;
    showOldPrice: boolean;
    showReferencePrice?: boolean;
    showFromOldPrice: boolean;
    showFromPrice: boolean;
    dynamicFieldCode: string;
    dynamicFieldCodeList?: MultiSelectOption[];
    enableBookingsEngineLink: boolean;
    displayPriceInfo: string;
    showDateAndStay: boolean;
    hideArrivalDate: boolean;
    hideStayPeriod: boolean;
    hideMinimumStayPeriod: boolean;
    hideSpecialInPriceInfo?: boolean;
    tabLink?: string;
    toggleButton: false;
    ElementToToggle: "";
    showInfoIconAfterPrice: boolean;
    showTotalPrice: boolean;
    dateFormat: string;
    freeActivityLocalization: LocalizedActivityPrice[];
    showDepartureDate?: boolean;
    showFromPriceForSubjectBasedActivity?: boolean;
    hideDynamicTextForSubjectFromPrice?: boolean;
    showTotalNightPrice?: boolean;
    disableCurrencySymbol: boolean;
}
export interface MultiSelectOption {
    value: string;
    label: string;
}
export interface LocalizedActivityPrice extends LocalizedContentBase {
    freeActivity: string;
    noPriceText: string;
}
const TARGETS = ["price"];

// eslint-disable-next-line max-lines-per-function
const widgetOptionsForm = ({ widgetOptionsFormId }: WidgetOptionsFormParams): FormSpec<WidgetOptions> => ({
    id: widgetOptionsFormId,
    name: getI18nLocaleObject(namespaceList.widgetResultsPrice, "price"),
    pluralName: getI18nLocaleObject(namespaceList.widgetResultsPrice, "prices"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            {
                                variable: "dynamicFieldCode",
                                type: "text",
                                label: I18nDYNAMICFIELDCODE,
                                visible: () => false,
                            },
                            dynamicFieldPrice("dynamicFieldCodeList"),
                            {
                                variable: "showDateAndStay",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showDateAndStay"),
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "descriptionDateStayOptions"),
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                            },
                            {
                                variable: "dateFormat",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "selectDateFormat"),
                                optionList: dateFormats,
                                type: "select",
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                            },
                            {
                                variable: "hideArrivalDate",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "hideArrivalDate"),
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                            },
                            {
                                variable: "showDepartureDate",
                                label: getI18nLocaleObject(namespaceList.admin, "showDepartureDate"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                                default: false,
                            },
                            {
                                variable: "hideStayPeriod",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "hideStayPeriod"),
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                            },
                            {
                                variable: "hideMinimumStayPeriod",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "hideMinimumStayPeriod"),
                                visible: (options: WidgetOptions) => options.showDateAndStay,
                            },
                            {
                                variable: "showReferencePrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "showReferencePrice"),
                                visible: (options: WidgetOptions) => !options.showOldPrice,
                            },
                            {
                                variable: "showOldPrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showOldPrice"),
                                visible: (options: WidgetOptions) => !options.showReferencePrice,
                            },
                            {
                                variable: "showFromOldPrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showFromOldPrice"),
                                visible: (options: WidgetOptions) => options.showOldPrice,
                            },
                            {
                                variable: "hideSpecialInPriceInfo",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "hideSpecialInPriceInfo"),
                                type: "checkbox",
                            },
                            {
                                variable: "perNight",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "perNight"),
                            },
                            {
                                variable: "showTotalNightPrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showTotalNightPrice"),
                                visible: (options: WidgetOptions) => !!options.perNight,
                            },
                            {
                                variable: "showTotalPrice",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "showTotalPrice"),
                                type: "checkbox",
                            },
                            {
                                variable: "showFromPriceForSubjectBasedActivity",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showFromPriceForSubjectBasedActivity"),
                                type: "checkbox",
                            },
                            {
                                variable: "hideDynamicTextForSubjectFromPrice",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "hideDynamicTextForSubjectFromPrice"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!options.showFromPriceForSubjectBasedActivity,
                            },
                            {
                                variable: "showFromPrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showFromBeforePrice"),
                                visible: (options: WidgetOptions) => options.showTotalPrice,
                            },
                            {
                                variable: "showInfoIconAfterPrice",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "showInfoIconAfterPrice"),
                            },
                            {
                                variable: "disableCurrencySymbol",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "disableCurrencySymbol"),
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            localized({
                                variable: "freeActivityLocalization",
                                tabContent: [
                                    {
                                        variable: "freeActivity",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "freeActivity"),
                                        type: "text",
                                    },
                                    {
                                        variable: "noPriceText",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "noPriceText"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            tabLinkAutocomplete("tabLink"),
                            {
                                variable: "enableBookingsEngineLink",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "enableBookingsEngineLink"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "priceFontSize",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceFontSize"),
                                type: "select",
                                default: "default-font-size",
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "priceFontColor",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceFontColor"),
                                type: "dual-color",
                                default: "font-default",
                            },
                            {
                                variable: "priceFontStyle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceFontStyle"),
                                type: "select",
                                default: "default",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "background-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "backgroundText"),
                                    },
                                ],
                            },
                            {
                                variable: "priceTextBackground",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceTextBackground"),
                                type: "dual-color",
                                default: "background-default",
                                visible: (options: WidgetOptions) => options.priceFontStyle === "background-text",
                            },
                            {
                                variable: "displayPriceInfo",
                                label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "displayPriceInfo"),
                                type: "select",
                                default: DISPLAY_PRICE_INFO.DROPDOWN,
                                optionList: [
                                    {
                                        value: DISPLAY_PRICE_INFO.DROPDOWN,
                                        label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "viewAsDropdown"),
                                    },
                                    {
                                        value: DISPLAY_PRICE_INFO.MODAL,
                                        label: getI18nLocaleObject(namespaceList.admin, "viewAsModal"),
                                    },
                                    {
                                        value: DISPLAY_PRICE_INFO.NONE,
                                        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "none"),
                                    },
                                ],
                            },
                            ...titleStylingOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "revealTheContent"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "revealContentInfo"),
                            },
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                visible: (options: WidgetOptions): boolean => options.toggleButton,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
});

export const resultsPanelPriceWidget = ({ widgetId, widgetType, widgetOptionsFormId }: SharedWidgetParams): TypesearchContainerWidgetSpec<WidgetOptions> => ({
    id: widgetId,
    type: widgetType,
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceWidget"),
    description: getI18nLocaleObject(namespaceList.widgetResultsPrice, "priceWidgetDescription"),
    optionsForm: widgetOptionsForm({ widgetOptionsFormId }),
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        styleIds: [],
        perNight: false,
        priceFontSize: "default-font-size",
        priceFontColor: "font-default",
        priceFontStyle: "default",
        priceTextBackground: "background-default",
        showOldPrice: false,
        showFromOldPrice: false,
        showFromPrice: false,
        dynamicFieldCode: "",
        dynamicFieldCodeList: [],
        enableBookingsEngineLink: false,
        displayPriceInfo: DISPLAY_PRICE_INFO.DROPDOWN,
        toggleButton: false,
        ElementToToggle: "",
        showDateAndStay: false,
        hideArrivalDate: false,
        hideStayPeriod: false,
        hideMinimumStayPeriod: false,
        showInfoIconAfterPrice: false,
        showTotalPrice: false,
        dateFormat: DATE_FORMAT.DISPLAY,
        freeActivityLocalization: [],
        disableCurrencySymbol: false,
    }),
    container: false,
    async render(widget, context, sitemapPageLinkWidgetOptions, resultOptions) {
        let warmupState;
        if (isServerSide() && (!resultOptions || Object.keys(resultOptions).length > 0)) {
            warmupState = await PriceBase.warmupCache({
                options: widget.options,
                context,
                ...(resultOptions || {}),
            });
        }
        return <Price options={widget.options} context={context} warmupState={warmupState} />;
    },
});
